import { QueryClient } from "@tanstack/react-query";
import { MAX_FAILURE_COUNT } from "data/api.constants";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Retry only for network errors
        return error.isNetworkError && failureCount < MAX_FAILURE_COUNT;
      },
    },
  },
});
